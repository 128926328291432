import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PandemicInformation = makeShortcode("PandemicInformation");
const Grid = makeShortcode("Grid");
const Img = makeShortcode("Img");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "what-about-screen-time"
    }}>{`What about screen time?`}</h1>
    <p>{`Spending time online or on electronic devices is a normal part of life for most teenagers. Young people may use technology to stay connected with friends and family, for relaxation or down-time, to learn or study, and even for their health (e.g. looking up health information).`}</p>
    <PandemicInformation mdxType="PandemicInformation">
  During the pandemic, most of us have had to increase the time we spend on devices. Now more than ever, it’s important
  to focus on the elements of screen time that we can control. In particular, consider the context of screen use and how
  screen time is impacting on other areas of life.
    </PandemicInformation>
    <h3 {...{
      "id": "context-matters"
    }}>{`Context matters.`}</h3>
    <p>{`Technology in itself is neutral – it’s the `}<em parentName="p">{`how`}</em>{`, `}<em parentName="p">{`what`}</em>{`, `}<em parentName="p">{`who`}</em>{`, `}<em parentName="p">{`when`}</em>{` and `}<em parentName="p">{`why`}</em>{` of its use that determine the outcomes. Screen use might become a problem if it interferes with other parts of your teen’s life, like sleep, being active, school, and spending time with family and friends.`}</p>
    <p>{`It’s important to think about `}<strong parentName="p">{`what`}</strong>{` your teen is doing online, and `}<strong parentName="p">{`why`}</strong>{` (what they enjoy or hooks them in). Engaging in `}<strong parentName="p">{`good-quality and helpful content`}</strong>{` can contribute to your teen’s learning, creativity, and social development. But not all online content is helpful, so it’s important to help your teen to distinguish between what’s helpful and what isn’t.`}</p>
    <Grid container spacing={3} mdxType="Grid">
  <Grid item xs={12} sm={6} mdxType="Grid">
    <div>
      <h5>Is my teen spending too much time online?</h5>
      <p>
        There’s no ‘golden rule’ for how much screen time teenagers should have. However, research suggests that
        teenagers who spend more time online (particularly on social media) may have more symptoms of depression and
        anxiety. This does not mean that social media <i>causes</i> depression or anxiety, but it does suggest that
        there may be a link between the two.
      </p>
      <p>
        Experts suggest that parents limit their teen’s screen time and ensure they are not spending excessive time
        online.
      </p>
      <p>
        What is “excessive” will differ for each teen/family. Use the activity on the next page to help you consider
        whether your teen is spending too much time online.
      </p>
    </div>
  </Grid>
  <Grid item xs={12} sm={6} mdxType="Grid">
    <Img src="/images/shared/55.svg" alt="parent controls" mdxType="Img" />
  </Grid>
    </Grid>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      